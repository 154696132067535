import React, { useEffect, useState, useContext, useRef } from "react";
import CartButton from "../CartButton/CartButton";
import "./CartCard.css";
import box from "../../assets/images/placeholder.svg";
import { useHistory, useLocation, useParams } from "react-router";
import { IMAGE_BASE_URL } from "../../api/config";
import { FaHeart, FaRegHeart } from "react-icons/fa";
// import { AiFillThunderbolt, AiOutlineThunderbolt } from "react-icons/ai";
// TagManager
import TagManager from "react-gtm-module";
import { AppContext } from "../../context/AppContext";
import {
  addToCart,
  updateToCart,
  deleteFromCart,
  addToWishList,
  removeFromWishList
} from "../../api";
import { confirmAlert } from "react-confirm-alert";
import useOnScreen from "../../hooks/useOnScreen";
import { getCategoryNames } from "../../utils/getCategoryNames";

const CartCard = props => {
  const {
    cartId,
    cartData,
    isLoggedIn,
    setShowLogin,
    wishlistData,
    addToFavourites,
    removeFromFavourites,
    setShowToast,
    setToastData,
    currentStore,
    addToCartContext,
    updateCartContext,
    removeFromCartContext
  } = useContext(AppContext);
  const history = useHistory();
  const [cartValue, setCartValue] = useState(0);
  const [updatedValue, setUpdatedValue] = useState(0);
  const [isCart, setIsCart] = useState(false);
  const [hasClicked, setHasClicked] = useState(0);
  const [isCartLoading, setIsCartLoading] = useState(true);
  // const [flashIcon, setFlashIcon] = useState(false);
  const [favoriteIcon, setFavoriteIcon] = useState(false);
  const [favouriteAnim, setFavouriteAnim] = useState(false);
  const ref = useRef();
  const [discount, setDiscount] = useState(0);

  const onScreen = useOnScreen(ref);

  useEffect(async () => {
    if (onScreen) {
      props?.setimpressionList([
        ...props?.impressionList,
        // Array of promoFieldObjects.

        // Array of promoFieldObjects.
        {
          id: props?.item?.sku, // ID or Name is required.
          name: props?.item?.name,
          price:
            props?.item?.tax_included_special_price === "0"
              ? props?.item?.tax_included_price
              : props?.item?.tax_included_special_price,
          brand: "",
          category: props?.from,
          variant: "",
          list: props?.from,
          position: props?.index
        }
      ]);
    } else {
      props?.setimpressionList(
        props?.impressionList?.filter(i => i?.id !== props?.item?.sku)
      );
    }
  }, [onScreen]);
  function handleNoImage(el) {
    el.target.src = box;
  }
  const handleFavorite = item => {
    if (isLoggedIn) {
      setFavouriteAnim(true);
      if (favoriteIcon) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body confirm-popup-button-body">
                <h2 className="fw-700">{"Do you want to remove this item?"}</h2>
                <div className="btn-group d-flex justify-content-end">
                  <button
                    className={"confirm-popup-button confirm-button-update"}
                    onClick={() => {
                      removeFromWishList(
                        item?.entity_id,
                        currentStore?.store_code
                      )
                        .then(res => {
                          if (res) {
                            setFavoriteIcon(!favoriteIcon);
                            removeFromFavourites(item?.entity_id);
                            setShowToast(true);
                            setToastData({
                              type: "success",
                              text: "Removed from Favourite"
                            });
                          } else {
                            setShowToast(true);
                            setToastData({
                              type: "error",
                              text: "Something went wrong! Failed to remove from Favourite"
                            });
                          }
                        })
                        .catch(e => {
                          setShowToast(true);
                          setToastData({
                            type: "error",
                            text: e.message
                          });
                        })
                        .finally(() => {
                          setFavouriteAnim(false);
                        });
                      onClose();
                    }}
                  >
                    {"Yes"}
                  </button>
                  <button
                    className={"cancel-popup-button cancel-button-update"}
                    onClick={() => {
                      setFavouriteAnim(false);
                      onClose();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            );
          },
          closeOnClickOutside: true
        });
      } else {
        addToWishList(item?.entity_id, currentStore?.store_code)
          .then(res => {
            if (res) {
              process?.env?.REACT_APP_ENV === "production" && 
              window.gtag("event", "add_to_wishlist", {
                currency: "AED",
                value: parseFloat(props?.item?.tax_included_price || props?.item?.price).toFixed(2),
                items: [
                  {
                    item_id: props?.item?.sku,
                    item_name: props?.item?.name,
                    currency: "AED",
                    index: 0,
                    price: props?.item?.price
                  }
                ]
              });
              setFavoriteIcon(!favoriteIcon);
              addToFavourites(item);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Added to Favourite"
              });
            } else {
              setShowToast(true);
              setToastData({
                type: "error",
                text: "Something went wrong! Failed to add to Favourite"
              });
            }
          })
          .catch(e => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => {
            setFavouriteAnim(false);
          });
      }
    } else setShowLogin(true);
  };

  useEffect(() => {
    setFavoriteIcon(
      wishlistData?.filter(item => item.entity_id === props?.item?.entity_id)
        .length > 0
        ? true
        : false
    );
  }, [wishlistData]);

  useEffect(() => {
    let mDiscount = props?.item?.cross_price
      ? ((props?.item?.cross_price - props?.item?.tax_included_special_price) /
          props?.item?.cross_price) *
        100
      : ((props?.item?.tax_included_price -
          props?.item?.tax_included_special_price) /
          props?.item?.tax_included_price) *
        100;
    if (!isFinite(mDiscount)) {
      mDiscount = 0;
    }
    setDiscount(mDiscount);
  }, [props?.item]);
  const cartDataLogger = (eventName, data) => {
    if (eventName === "addToCart") {
      process?.env?.REACT_APP_ENV === "production" && 
      window.gtag("event", "add_to_cart", {
        currency: "AED",
        value: parseFloat(data?.tax_included_price || data?.price).toFixed(2),
        items: [
          {
            item_id: data?.sku,
            item_name: data?.name,
            currency: "AED",
            index: 0,
            price: data?.price,
            quantity: data?.qty
          }
        ]
      });
    }else if(eventName === "removeFromCart") {
      process?.env?.REACT_APP_ENV === "production" && 
      window.gtag("event", "remove_from_cart", {
        currency: "AED",
        value: parseFloat(data?.tax_included_price || data?.price).toFixed(2),
        items: [
          {
            item_id: data?.sku,
            item_name: data?.name,
            currency: "AED",
            index: 0,
            price: data?.price,
            quantity: data?.qty
          }
        ]
      });
    }
  };
  function handleCart(methodToDo) {
    setIsCartLoading(true);
    const addToCartData = {
      cartItem: {
        quote_id: cartId,
        sku: props.item.sku,
        qty: cartValue
      }
    };
    switch (methodToDo) {
      case "addToCart":
        if (isLoggedIn) {
          setIsCart(true);
          addToCart(currentStore?.store_code, addToCartData)
            .then(res => {
              cartDataLogger("addToCart", res);
              addToCartContext(res?.item_id, props.item, res?.qty);
              setUpdatedValue(cartValue);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Product added to cart"
              });
            })
            .catch(e => {
              setShowToast(true);
              setCartValue(0);
              setIsCart(false);
              setToastData({
                type: "error",
                text: e.message
              });
            })
            .finally(() => setIsCartLoading(false));
        } else {
          setShowLogin(true);
        }
        break;

      case "updateToCart":
        const cartItemUpdate = cartData?.filter(
          item => item.sku === props?.item?.sku
        );
        updateToCart(
          currentStore?.store_code,
          cartItemUpdate[0]?.item_id,
          addToCartData
        )
          .then(res => {
            cartDataLogger("addToCart", res);
            updateCartContext(res?.item_id, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Quantity Updated to cart"
            });
          })
          .catch(e => {
            setCartValue(oldCartValue => oldCartValue - 1);
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => setIsCartLoading(false));

        break;
      case "deleteFromCart":
        setIsCart(false);
        const cartItemDelete = cartData?.filter(
          item => item.sku === props?.item?.sku
        );
        deleteFromCart(currentStore?.store_code, cartItemDelete[0]?.item_id)
          .then(res => {
            if (res) {
              cartDataLogger("removeFromCart", cartItemDelete[0]);
              removeFromCartContext(cartItemDelete[0]?.item_id);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Product removed from cart"
              });
            }
          })
          .catch(e => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => setIsCartLoading(false));
        break;
    }
  }

  useEffect(() => {
    if (hasClicked === 0) return;
    if (isCart && cartValue == 0) return handleCart("deleteFromCart");
    if (isCart && cartValue > 0) return handleCart("updateToCart");
    if (!isCart && cartValue > 0) return handleCart("addToCart");
  }, [hasClicked]);

  useEffect(() => {
    let filteredSku = [];
    filteredSku = cartData?.filter(item => item.sku === props?.item?.sku);
    if (filteredSku && filteredSku[0]?.qty) {
      setIsCart(true);
      setCartValue(filteredSku[0]?.qty);
      setUpdatedValue(filteredSku[0]?.qty);
    } else {
      setIsCart(false);
      setCartValue(0);
      setUpdatedValue(0);
    }
    setIsCartLoading(false);
  }, [cartData]);

  const clickHandler = () => {
    history.push(`/products/${props.item.sku}`);
  };

  return (
    <div
      id={props?.item?.entity_id}
      ref={ref}
      className={`container-fluid ${props?.className} p-3 position-relative`}
    >
      <div className="d-flex" style={{ position: "relative", zIndex: 1 }}>
        <div
          className="position-absolute c-pointer"
          onClick={() => handleFavorite(props?.item)}
          style={{
            right: "-5px",
            top: "-10px"
          }}
        >
          {favoriteIcon ? (
            <FaHeart
              fill="green"
              size={20}
              className={"fav-icons" + (favouriteAnim ? " heart-beat" : "")}
            />
          ) : (
            <FaRegHeart
              size={20}
              className={"fav-icons" + (favouriteAnim ? " heart-beat" : "")}
            />
          )}
        </div>
      </div>
      <div className="d-flex" onClick={clickHandler}>
        <img
          className="cart-card-image mx-auto"
          onError={handleNoImage}
          src={
            props.item?.image
              ? IMAGE_BASE_URL + "/catalog/product" + props.item.image
              : box
          }
          alt="product"
        />
      </div>
      <div className="cart-card-lower">
        <div className="cart-card-product-name text-module line-clamp text-center mb-2">
          {props?.item?.name}
        </div>
        <div className="d-flex justify-content-center">
          {props?.item?.tax_included_special_price > 0 ? (
            <div className="cart-qd-box cart-discount">
              {discount.toFixed()}%
            </div>
          ) : (
            <div className="cart-qd-box cart-discount" style={{ opacity: 0 }}>
              {(
                ((props?.item?.tax_included_price -
                  props?.item?.tax_included_special_price) /
                  props?.item?.tax_included_price) *
                100
              ).toFixed()}
              %
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {props?.item?.tax_included_special_price > 0 ? (
            <>
              <p className="price-strike my-0">
                AED{" "}
                {parseFloat(
                  props.item?.cross_price
                    ? props.item?.cross_price
                    : props.item?.tax_included_price
                ).toFixed(2)}
              </p>
              <p className="cart-price-special my-2 text-center">
                {"AED " +
                  parseFloat(
                    props.item?.tax_included_special_price || 0
                  ).toFixed(2) || "null"}
              </p>
            </>
          ) : (
            <p className="cart-price-special my-2 text-center">
              {"AED " +
                parseFloat(props?.item?.tax_included_price || 0).toFixed(2) ||
                "null"}
            </p>
          )}
        </div>
        {props?.item?.is_salable === 1 ? (
          <CartButton
            updatedValue={updatedValue}
            hasClicked={hasClicked}
            setHasClicked={setHasClicked}
            cartValue={cartValue}
            setCartValue={setCartValue}
            isCartLoading={isCartLoading}
          />
        ) : (
          <div className="no-stock">Out of Stock</div>
        )}
      </div>
      {props?.item?.salable_qty &&
      0 < props?.item?.salable_qty[0]?.qty &&
      props?.item?.salable_qty[0]?.qty < 10 ? (
        <div className="hurry-items">
          Hurry! only {props?.item?.salable_qty[0]?.qty} left
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CartCard;
