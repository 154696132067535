import React, { useContext, useEffect, useState } from "react";
import {
  cardPayment,
  checkPaymentStatus,
  createCart,
  createOrder,
  getOrder,
  getProductFromSku
} from "../../api";
import StatusDisplay from "../../components/StatusDisplay";
import loader from "../../assets/images/loader.gif";
import { AppContext } from "../../context/AppContext";
import * as successAnimation from "../../assets/animations/success.json";
import * as failureAnimation from "../../assets/animations/failed.json";
import * as loaderAnimation from "../../assets/animations/loader.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useHistory } from "react-router";
import TagManager from "react-gtm-module";
import { findChildAmongCategories } from "../../utils/findChildAmongCategories";

const CheckOrderStatus = props => {
  const history = useHistory();
  const {
    activePaymentPayload,
    currentStore,
    initializeCart,
    updateActivePaymentPayload,
    checkoutTotals,
    cartId,
    cartData,
    setShowToast,
    setToastData,
    setBreadcrumbs,
    categories
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [currentOrderId, setCurrentOrderId] = useState();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [pageData, setPageData] = useState({
    picture: "",
    heading: "",
    details: ""
  });
  let tempCartData = [];

  useEffect(() => {
    if (cartData?.length > 0) {
      tempCartData = cartData;
    }
  }, [cartData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    setBreadcrumbs(
      props?.match?.params?.statusType === "order-return" ||
        props?.match?.params?.statusType === "order-cancel" ||
        props?.match?.params?.statusType === "edit-success"
        ? [
            { title: "Home", path: "/" },
            { title: "Orders", path: "/account/orders" },
            { title: "Order Status" }
          ]
        : [
            { title: "Home", path: "/" },
            { title: "Cart", path: "/cart" },
            { title: "Checkout", path: "/checkout" },
            { title: "Order Status" }
          ]
    );
  }, []);

  const handlePaymentRetry = () => {
    const payload = {
      env: process?.env?.REACT_APP_ENV,
      quot_id: cartId,
      currencyCode: checkoutTotals?.base_currency_code,
      value: checkoutTotals?.base_grand_total,
      redirectUrl:
        `https://gateway.nesto.shop/auth/payment/status?env=staging&payment=` +
        encodeURIComponent(
          window.location.href.replace(props.location.search, "")
        ),
      sub_total: checkoutTotals?.base_subtotal
    };
    cardPayment(payload)
      .then(res => {
        window.location.href = res.payment_link;
      })
      .catch(() => {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Something went wrong!"
        });
      });
  };

  // online payment

  useEffect(() => {
    if (props.location.search) {
      activePaymentPayload?.custom_data?.timeslot_id &&
        checkPaymentStatus(props.location.search.replace("?ref=", "")).then(
          res => {
            if (res?.status === "AUTHORISED") {
              createOrderFN(
                props.location.search.replace("?ref=", ""),
                "ngeniusonline"
              );
            } else {
              setPaymentStatus("order-failed");
            }
          }
        );
    }
  }, [props.location.search, activePaymentPayload]);

  // cash on delivery

  useEffect(() => {
    if (!props.location.search) {
      if (props.match?.params?.orderId) {
        setCurrentOrderId(props.match?.params?.orderId);
      }
      setPaymentStatus(props?.match?.params?.statusType);
    }
  }, [props?.match?.params?.statusType, activePaymentPayload]);

  // order creation

  const createOrderFN = (refId, paymentMethod) => {
    if (
      activePaymentPayload?.custom_data?.timeslot_id &&
      activePaymentPayload?.custom_data?.lat &&
      activePaymentPayload?.custom_data?.long
    ) {
      createOrder(currentStore?.store_code, {
        ...activePaymentPayload,
        reference_id: refId,
        payment_method: paymentMethod
      })
        .then(res => {
          updateActivePaymentPayload({}, {});
          setCurrentOrderId(res?.orderid);
          let prods = []; 
          getOrder(res?.orderid).then(orderRes => {
            getOrder(res?.orderid).then((requestOrderRes) => {
              let skus = requestOrderRes?.items?.map((prod) => prod?.sku);
              getProductFromSku(currentStore?.store_code, skus).then((skuRes) => {
                orderRes?.items?.forEach((i) => {
                  let itemCategories = skuRes.filter(
                    (it) => it?.sku === i?.sku
                  )[0]?.category_ids;
                  let currentCategory = "";
                  if (itemCategories.length >= 3) {
                    currentCategory = findChildAmongCategories(
                    categories?.children_data,
                    itemCategories[2]
                    );
                  }
                  prods.push({
                    name: i?.name,
                    id: i?.sku,
                    price: i?.price_incl_tax,
                    brand: "",
                    category: "",
                    item_list_name: currentCategory || "",
                    variant: "",
                    quantity: parseInt(i?.qty_ordered),
                  });
                });
                window.gtag("event", "purchase", {
                  transaction_id: res?.orderid, //Transaction id is required for ecommerce transactions. The value of this field should be unique to each transaction, and should not change over the lifetime of the transaction.
                  affiliation: "Online Store",
                  value: checkoutTotals?.base_grand_total, // Total transaction value (incl. tax and shipping)
                  tax:
                    checkoutTotals?.base_grand_total -
                    checkoutTotals?.base_subtotal,
                  shipping: checkoutTotals?.shipping_incl_tax,
                  currency: "AED",
                  coupon: orderRes?.coupon || "",
                  items: prods,
                });
              });
            });

            process?.env?.REACT_APP_ENV === "production" &&
            window.gtag("event", "payment_success");

          });
          createCart(currentStore?.store_code).then(cartres => {
            initializeCart({ id: cartres, items: [] });
            setPaymentStatus("order-placed");
          });
        })
        .catch(e => {
          setPaymentStatus("order-failed");
          process?.env?.REACT_APP_ENV === "production" &&
            window.gtag("event", "payment_failure");
        });
    } else {
      setPaymentStatus("order-failed");
      process?.env?.REACT_APP_ENV === "production" &&
            window.gtag("event", "payment_failure");
    }
  };

  const handleViewOrderDetails = () => {
    history.push(`/orderdetails/${currentOrderId}`);
  };

  useEffect(() => {
    switch (paymentStatus) {
      case "order-placed":
        setPageData({
          showRetry: false,
          loop: false,
          picture: successAnimation,
          heading: "Order Placed",
          details:
            "Your order has been confirmed. You can check the status of this order from my orders page."
        });
        setLoading(false);
        break;
      case "order-failed":
        setPageData({
          showRetry: props.location.search ? true : false,
          showViewOrderDetails: false,
          picture: failureAnimation,
          loop: false,
          heading: "Order Failed",
          details:
            "Sorry, we couldnt place the order for you. Please try again."
        });
        setLoading(false);
        break;
      case "order-cancel":
        setPageData({
          showRetry: false,
          loop: false,
          showViewOrderDetails: false,
          picture: successAnimation,
          heading: "Order Cancelled",
          details:
            "Your order has been cancelled. You can check the status of this order from my orders page"
        });
        setLoading(false);
        break;
      case "order-return":
        setPageData({
          showRetry: false,
          showViewOrderDetails: false,
          loop: false,
          picture: successAnimation,
          heading: "Order Returned",
          details:
            "Your order return has been initiated. You can check the status of this order from my orders page"
        });
        setLoading(false);
        break;
      default:
        setPageData({
          showRetry: false,
          loop: true,
          picture: loaderAnimation,
          showViewOrderDetails: false,
          heading: "Getting your order Status",
          details: "Your order status is being retrieved. Please wait"
        });
        setLoading(true);
    }
  }, [paymentStatus]);

  useDocumentTitle("Order Status - Nesto Online Shopping");
  return (
    <div className="success-container p-2">
      <h3 className="text-center">Order Status</h3>
      <StatusDisplay
        animationData={pageData?.picture}
        heading={pageData?.heading}
        details={pageData?.details}
        showRetry={pageData?.showRetry}
        showViewOrderDetails={
          typeof currentOrderId !== "undefined" &&
          paymentStatus === "order-placed"
        }
        loop={pageData?.loop}
        loading={loading}
        handlePaymentRetry={handlePaymentRetry}
        handleViewOrderDetails={handleViewOrderDetails}
      />
    </div>
  );
};

export default CheckOrderStatus;
