import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";

const NoMarginOnlyImage = ({ componentDesignData }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const { width } = useWindowSize();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };
  return (
    <div style={{ width: "100%", cursor: "pointer" }}>
      <Link
        to={() =>
          getRedirectionUrl(
            componentDesignData?.subTemplate?.widget[0].details.redirectType,
            componentDesignData?.subTemplate?.widget[0].details.itemCode
          )
        }
        style={{ all: "unset", cursor: "pointer" }}
      >
        <img
          style={{ width: "100%", maxHeight: "280px" }}
          src={
            width >= 445
              ? componentDesignData?.subTemplate?.widget[0].details.imageUrlWeb
                ? componentDesignData?.subTemplate?.widget[0].details
                    .imageUrlWeb
                : componentDesignData?.subTemplate?.widget[0].details.imageUrl
              : componentDesignData?.subTemplate?.widget[0].details.imageUrl
          }
          alt={componentDesignData?.subTemplate?.widget[0].details.name}
        />
      </Link>
    </div>
  );
};

export default NoMarginOnlyImage;
