// to find if the category id of product exists among the global list of categories and return its name

export const findChildAmongCategories = (arr, childId) => {
  let temp = null;
  arr.forEach((c) => {
    if (c?.id === childId) {
      temp = c?.name;
    }
    if (!temp && c?.children_data.length > 0) {
      temp = findChildAmongCategories(c?.children_data, childId);
    }
  });
  return temp;
};
