import { GoogleMap, Marker } from "@react-google-maps/api";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import useOnclickOutside from "react-cool-onclickoutside";
import Geocode from "react-geocode";
import { BiCurrentLocation } from "react-icons/bi";
import { useHistory } from "react-router";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { checkLocation } from "../../api";
import { MAP_API_KEY } from "../../api/config";
import { AppContext } from "../../context/AppContext";
import "./SelectLocation.css";

const SelectLocation = ({
  handleHideLocationModal,
  showLocationModal,
  setShowLocationModal,
}) => {
  const {
    setShowToast,
    setToastData,
    updateLocation,
    updateCurrentStore,
    setShowMap,
    currentStore,
    latLong,
    locationData,
    setLocationData,
  } = useContext(AppContext);
  const [isLocationConfirmed, setIsLocationConfirmed] = useState(false);
  const [cursor, setCursor] = useState(0);

  const containerStyle = {
    height: "500px",
    width: "100%",
  };
  const history = useHistory();

  useEffect(() => {
    console.log("let lof ", latLong);
    setLocationData({ lat: latLong?.lat, long: latLong?.long });
  }, []);

  const getLocationData = (latLng) => {
    let obj;
    Geocode.setApiKey(MAP_API_KEY);
    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
      (res) => {
        obj = {
          lat: latLng?.lat,
          long: latLng?.lng,
          name: res?.results[0]?.formatted_address,
          city: {
            name: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "administrative_area_level_1"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "administrative_area_level_1"
            )[0]?.short_name,
          },
          country: {
            name: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "country"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "country"
            )[0]?.short_name,
          },
        };
        setLocationData(obj);
        setValue(obj?.name);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      // console.log("description", description);
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng, name } = getLatLng(results[0]);
        setLocationData({
          lat: lat,
          long: lng,
          name: results[0]?.formatted_address,
        });
        setIsLocationConfirmed(true);
      });
    };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      setCursor((prevCursor) => prevCursor - 1);
    } else if (e.keyCode === 40 && cursor < data.length - 1) {
      setCursor((prevCursor) => prevCursor + 1);
    }
  };

  const handleAutoDetect = () => {
    navigator.geolocation.getCurrentPosition((position) =>
      getLocationData({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    );
  };

  const handleConfirmLocation = () => {
    checkLocation({
      point: {
        lat: locationData?.lat,
        long: locationData?.long,
      },
    }).then((res) => {
      if (!res?.data?.isPointInPolygon) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Selected Location is Not Deliverable",
        });
      } else {
        if (
          currentStore?.store_id &&
          res?.data?.stores[0]?.store_id !== currentStore?.store_id
        ) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body confirm-popup-button-body">
                  <h2 className="fw-700">Confirm Store Change</h2>
                  <div>
                    The location you have chosen is serviced by a different
                    store, Hence your cart and wishlist will be replaced with
                    that of the selected store.
                  </div>
                  <div className="btn-group d-flex justify-content-end">
                    <button
                      className={"confirm-popup-button confirm-button-update"}
                      onClick={() => {
                        updateLocation(
                          {
                            lat: locationData?.lat,
                            long: locationData?.long,
                            location: locationData?.name,
                            city: locationData?.city,
                            country: locationData?.country,
                          },
                          res?.data
                        );
                        updateCurrentStore(res?.data?.stores[0]);
                        onClose();
                        history.push("/");
                      }}
                    >
                      {"Yes"}
                    </button>
                    <button
                      className={"cancel-popup-button cancel-button-update"}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              );
            },
            closeOnClickOutside: true,
          });
        } else {
          updateLocation(
            {
              lat: locationData?.lat,
              long: locationData?.long,
              location: locationData?.name,
              city: locationData?.city,
              country: locationData?.country,
            },
            res?.data
          );
          updateCurrentStore(res?.data?.stores[0]);
          setShowLocationModal(false);
          history.push("/");
        }
      }
    });
  };

  console.log("location-data", locationData);

  return true ? (
    <Modal
      dialogClassName="select-location-modal"
      size="lg"
      show={showLocationModal}
      onHide={handleHideLocationModal}
      backdrop={!latLong?.location ? "static" : true}
    >
      <div className="location-modal-content-wrapper">
        <div className="location-modal-heading-wrapper">
          <div className="location-modal-heading">Select your Location</div>
          <button
            className="location-modal-close-btn"
            onClick={handleHideLocationModal}
          >
            close
          </button>
        </div>
        <div className="location-modal-hr" />
        <div className="location-modal-sub-heading">
          Choose your location to get started
        </div>
        <div ref={ref} className="location-modal-search-input-container">
          <div
            style={{ position: "relative" }}
            className="location-modal-suggetions-container"
          >
            <div
              className="location-modal-location-search-box"
              style={{ display: "flex" }}
            >
              <input
                type="text"
                placeholder="Eg. Jumeirah Beach Residence, Dubai"
                onChange={(e) => handleInput(e)}
                value={value}
                onKeyDown={handleKeyDown}
                autoFocus
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setValue(data[cursor].description, false);
                    clearSuggestions();

                    // Get latitude and longitude via utility functions
                    getGeocode({ address: data[cursor].description }).then(
                      (results) => {
                        const { lat, lng, name } = getLatLng(results[0]);
                        setLocationData({
                          lat: lat,
                          long: lng,
                          name: results[0]?.formatted_address,
                        });
                        setIsLocationConfirmed(true);
                      }
                    );
                  }
                }}
              />
              <div
                className="auto-detect-btn-wrapper"
                onClick={handleAutoDetect}
              >
                <BiCurrentLocation />
                <div>Auto detect</div>
              </div>
            </div>
            <div className="location-search-suggesstion-container">
              {data?.map((suggestion, i) => (
                <>
                  <div
                    className={
                      cursor === i
                        ? "location-search-suggestion active-suggestion"
                        : "location-search-suggestion"
                    }
                    onClick={handleSelect(suggestion)}
                    onMouseEnter={() => setCursor(i)}
                    onMouseLeave={() => setCursor(0)}
                  >
                    {suggestion.description}
                  </div>
                </>
              ))}
            </div>
          </div>
          <button
            className={
              isLocationConfirmed
                ? "location-modal-start-shopping-btn"
                : "location-modal-start-shopping-btn-disabled"
            }
            onClick={() => {
              if (isLocationConfirmed) {
                handleConfirmLocation();
                setShowLocationModal(false);
              }
            }}
          >
            {latLong.location ? "Change Location" : "Start Shopping"}
          </button>
        </div>
        <GoogleMap
          center={{
            lat: locationData?.lat,
            lng: locationData?.long,
          }}
          mapContainerStyle={containerStyle}
          zoom={16}
          clickableIcons={false}
          onClick={(e) => getLocationData(e.latLng.toJSON())}
          
        >
          <Marker
							position={{
								lat: locationData?.lat,
								lng: locationData?.long,
							}}
						/>
        </GoogleMap>
      </div>
    </Modal>
  ) : (
    <div>Movile </div>
  );
};

export default SelectLocation;
