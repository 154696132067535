import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./Mainbar.css";
import newLogo from "../../assets/images/nesto-online-logo.svg";
import ModalPopup from "../ModalPopup/ModalPopup";
import Sidebar from "../Sidebar/Sidebar";
import { useHistory, useLocation } from "react-router-dom";
import box from "../../assets/images/placeholder.svg";
import { AppContext } from "../../context/AppContext";
import { Overlay, Popover, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FiChevronDown, FiLogOut, FiSearch, FiMenu } from "react-icons/fi";
import { ImProfile } from "react-icons/im";

import { GrLocation } from "react-icons/gr";
import { MdFavoriteBorder } from "react-icons/md";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { BiBell } from "react-icons/bi";
import useWindowSize from "../../hooks/useWindowSize";
import SelectLocation from "../SelectLocation/SelectLocation";

function Mainbar() {
  const cartRef = useRef(null);
  const favRef = useRef(null);
  const notiRef = useRef(null);
  const profileDropdown = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const {
    isLoggedIn,
    logout,
    profile,
    setShowLogin,
    cartData,
    currentStore,
    mapbar,
    setMapbar,
    store,
    updateCurrentStore,
    latLong,
    searchProductName,
    setSearchProductName,
    setShowToast,
    setToastData,
  } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showTT, setShowTT] = useState({});
  const [cartbar, setCartbar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const { width } = useWindowSize();
  const [showLocationModal, setShowLocationModal] = useState(false);

  const [selected, setSelected] = useState();

  const data = {
    time: "09:58",
    status: "Susbtitution required",
    statusinfo:
      "You have a total of 10 minutes to respond to this substitution request. Since you have allowed automatic substitutions, the store will auto pick a substitute if you fail to respond in time. ",
    image: "../../assets/images/placeholder.svg",
    order: "Order On Hold",
    date: "Thurs 28 January 2021",
    id: "#123ABC",
    price: "6 Items | 6005.00 AED",
    button: "Resolve",
  };

  useEffect(() => {
    if(!latLong?.location || location.pathname === "/select-location"){
      setShowLocationModal(true)
    }
  }, [])

  useEffect(() => {
    if (store?.stores?.length > 0 && !currentStore?.store_name) {
      updateCurrentStore(store?.stores[0]);
    }
  }, []);

  useEffect(() => {
    setShowTT({
      profile: false,
    });
  }, [isLoggedIn]);
  useEffect(() => {
    const searchTerm = query.get("name");
    if (searchTerm) {
      setSearchProductName(searchTerm);
    } else {
      setSearchProductName("");
    }
  }, [query, setSearchProductName]);

  const fixTwo = (x) => {
    let fix = Math.round(x * 100) / 100;
    return fix;
  };



  useEffect(() => {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    cartData?.map((item) => {
      mSubTotal += fixTwo(item?.qty * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
      );
    });

    setCartTotal(mSubTotal + mTaxTotal);
  }, [cartData]);



  useEffect(() => {
    const urlData = window.location.href.split("/");
    const len = urlData.length;
    if (
      urlData[len - 1] === "cart" ||
      urlData[len - 1] === "wishlist" ||
      urlData[len - 1] === "notification"
    ) {
      setSelected(urlData[len - 1]);
    } else {
      setSelected("");
    }
  }, [window.location.href]);

  useEffect(()=>{
    if(cartbar) {
      process?.env?.REACT_APP_ENV === "production" &&
      window.gtag("event", "view_cart", {
              currency: "AED",
              value:parseFloat(cartTotal).toFixed(2),
              items: cartData.map((item, index) => (
                {
                  item_id: item.sku,
                  item_name: item.name,
                  currency: "AED",
                  index: index,
                  price: item.price,
                  quantity: item.qty
                }
              ))
            });
    }
  }, [cartbar])

  const handleSearchProduct = () => {
    let regVar = /^\s+$/;
    if (searchProductName.length < 3) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Atleast 3 letter needed to search!",
      });
      return;
    }
    if (searchProductName !== "" && !regVar.test(searchProductName)) {
     (
       process?.env?.REACT_APP_ENV === "production" &&
      window.gtag("event", "search", {
        search_term: searchProductName
      }))
      history.push(`/search?name=${searchProductName}`);
    }
  };

  function handleSearchKeyUp(e) {
    if (e.code === "Enter" || e.keyCode === 13) {
      handleSearchProduct();
      return;
    }
    if (!e.code) {
      handleSearchProduct();
    }
  }
  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body confirm-popup-button-body">
            <h2 className="fw-700">{"Logout?"}</h2>
            <div>{"Are you sure to log out?"}</div>
            <div className="btn-group d-flex justify-content-end">
              <button
                className={"confirm-popup-button confirm-button-delete"}
                onClick={() => {
                  logout();
                  onClose();
                }}
              >
                {"Logout"}
              </button>
              <button
                className={"cancel-popup-button cancel-button-delete"}
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: true,
    });
  };

  const handleShowLocationModal = () => {
    setShowLocationModal(true);
  };

  const handleHideLocationModal = () => {
    setShowLocationModal(false);
  };
  return (
    <>
      <div className="mainbar container-fluid">
        <div className="container p-0 d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex align-items-center">
            <div
              className="header-icons-wrap ham-menu mobile-menu"
              onClick={() => {
                setMobileMenu(true);
              }}
            >
              <FiMenu className="header-icons" />
            </div>
            <img
              className="new-logo c-pointer"
              src={newLogo}
              alt="logo"
              onClick={() =>
                location.pathname === "/"
                  ? window.location.reload()
                  : history.push("/")
              }
            />
          </div>
          <div
            className="location-wrap c-pointer"
            onClick={() => {
              // history.push("/select-location");
              handleShowLocationModal();
            }}
          >
            <span className="location-name">
              <GrLocation className="location-icon" />
              {latLong?.location ? latLong?.location : "Select a Location"}
              {/* <br />
              <span className="store-name">{store?.website_name}</span> */}
            </span>
          </div>
          <form
            className="search-wrap"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearchKeyUp(e);
            }}
          >
            <input
              className="search-wrap-input"
              type="text"
              placeholder="What are you looking for...."
              value={decodeURIComponent(searchProductName)}
              onChange={(e) => {
                setSearchProductName(e.target.value);
              }}
              // onKeyPress={(e) => {
              //   handleSearchKeyUp(e);
              // }}
            />
            <div
              className="d-inline-block search-wrap-icon click-anim"
              onClick={handleSearchProduct}
            >
              <FiSearch className="search-wrap-svg" />
            </div>
          </form>
          <div className="d-inline-flex justify-content-between align-items-center user-actions-wrap">
            {isLoggedIn && (
              <>
                <div
                  className={
                    "header-icons-wrap position-relative c-pointer " +
                    (selected === "notification" ? "selected" : "")
                  }
                  ref={notiRef}
                  onClick={() => history.push("/account/notification")}
                  onMouseEnter={() =>
                    setShowTT((showTT) => ({ ...showTT, noti: true }))
                  }
                  onMouseLeave={() =>
                    setShowTT((showTT) => ({ ...showTT, noti: false }))
                  }
                >
                  <BiBell className="header-icons" />
                </div>
                <Overlay
                  target={notiRef.current}
                  show={showTT?.noti ? showTT?.noti : false}
                  placement="bottom"
                >
                  {(props) => (
                    <Tooltip id="tooltip-noti" {...props}>
                      Notifications
                    </Tooltip>
                  )}
                </Overlay>
                <div
                  className={
                    "header-icons-wrap position-relative c-pointer " +
                    (selected === "wishlist" ? "selected" : "")
                  }
                  ref={favRef}
                  onClick={() => history.push("/account/wishlist")}
                  onMouseEnter={() =>
                    setShowTT((showTT) => ({ ...showTT, fav: true }))
                  }
                  onMouseLeave={() =>
                    setShowTT((showTT) => ({ ...showTT, fav: false }))
                  }
                >
                  <MdFavoriteBorder className="header-icons" />
                </div>
                <Overlay
                  target={favRef.current}
                  show={showTT?.fav ? showTT?.fav : false}
                  placement="bottom"
                >
                  {(props) => (
                    <Tooltip id="tooltip-fav" {...props}>
                      Wishlist
                    </Tooltip>
                  )}
                </Overlay>
                <div
                  className={
                    "header-icon-pc-mob position-relative c-pointer" +
                    (selected === " cart" ? " selected" : "") +
                    (!cartData ? " cart-loading" : "")
                  }
                  onClick={() => setCartbar(true)}
                  ref={cartRef}
                  onMouseEnter={() =>
                    setShowTT((showTT) => ({ ...showTT, cart: true }))
                  }
                  onMouseLeave={() =>
                    setShowTT((showTT) => ({ ...showTT, cart: false }))
                  }
                >
                  <HiOutlineShoppingCart className="header-icons" />
                  {cartData && cartData?.length > 0 && (
                    <div className="user-action-indicator">
                      {cartData?.length}
                    </div>
                  )}
                </div>
                <Overlay
                  target={cartRef.current}
                  show={showTT?.cart && width > 768 ? showTT?.cart : false}
                  placement="bottom"
                >
                  {(props) => (
                    <Tooltip id="tooltip-cart" {...props}>
                      My Cart
                    </Tooltip>
                  )}
                </Overlay>
                <div>
                  <button
                    className="d-flex justify-content-center align-items-center user-wrap mobile-view"
                    ref={profileDropdown}
                    onMouseEnter={() =>
                      setShowTT((showTT) => ({ ...showTT, profiles: true }))
                    }
                    onMouseLeave={() =>
                      setShowTT((showTT) => ({ ...showTT, profiles: false }))
                    }
                  >
                    {profile?.firstname}
                    <FiChevronDown
                      color="#555"
                      size="18"
                      className={
                        "ml-1 arrow-rotate" +
                        (showTT.profiles === true ? " spin180" : "")
                      }
                    />
                  </button>
                  <Overlay
                    target={profileDropdown.current}
                    show={showTT?.profiles ? showTT?.profiles : false}
                    placement="bottom"
                  >
                    <Popover
                      id="popover-basics"
                      className="d-flex flex-column account-popover"
                      onMouseEnter={() =>
                        setShowTT((showTT) => ({ ...showTT, profiles: true }))
                      }
                      onMouseLeave={() =>
                        setShowTT((showTT) => ({ ...showTT, profiles: false }))
                      }
                    >
                      <Popover.Content
                        className="fs-15 c-pointer highlight"
                        onClick={() => history.push("/account/profile")}
                      >
                        <ImProfile className="mr-2" color="#B2B2B2" /> Profile
                      </Popover.Content>

                      <Popover.Content
                        className="fs-14 c-pointer highlight"
                        onClick={handleLogout}
                      >
                        <FiLogOut className="mr-2" color="#C71712" />
                        Log Out
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
              </>
            )}
            {!isLoggedIn ? (
              <button
                className="btn btn-success user-account-div d-flex justify-content-center align-items-center"
                style={{
                  background: "white",
                  color: "green",
                  border: "1.5px solid green",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                }}
                onClick={() => {
                  setShowLogin(true);
                }}
              >
                Log In
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* MOBILE MENU OVERLAY */}
        <div className="mobile-menu-overlay">
          <Sidebar
            sidebar={mobileMenu}
            setSidebar={setMobileMenu}
            location="left"
            type="mobile-menu"
            setCartbar={setCartbar}
          />
        </div>
      </div>

      <SelectLocation 
        handleShowLocationModal={handleShowLocationModal} 
        handleHideLocationModal={handleHideLocationModal} 
        showLocationModal={showLocationModal} 
        setShowLocationModal={setShowLocationModal}
      />

      <ModalPopup show={show} setShow={setShow}>
        <SubstitutionPopUp data={data} />
      </ModalPopup>
      <Sidebar
        sidebar={cartbar}
        setSidebar={setCartbar}
        location="right"
        type="cart"
      />
      <Sidebar
        sidebar={mapbar}
        setSidebar={setMapbar}
        location="left"
        type="map"
      />
    </>
  );
}
const SubstitutionPopUp = (props) => {
  return (
    <div className="text-center p-4">
      <p className="modal-time d-inline-block">{props.data.time}</p>
      <p className="modal-title mb-2">{props.data.status}</p>
      <p className="modal-info mb-4 px-md-5">{props.data.statusinfo}</p>
      <div className="d-inline-flex mb-4">
        <div className="modal-img-wrap mr-3">
          <img className="w-100 img-fluid" src={box} alt="product"></img>
        </div>
        <div className="modal-contents text-left">
          <p className="modal-content-title mb-2">{props.data.order}</p>
          <p className="modal-content-info mb-1">{props.data.date}</p>
          <p className="modal-content-info mb-1">{props.data.id}</p>
          <p className="modal-content-info mb-1">{props.data.price}</p>
        </div>
      </div>
      <div className="modal-button-wrap">
        <button className="modal-primary-btn">{props.data.button}</button>
      </div>
    </div>
  );
};
export default Mainbar;
