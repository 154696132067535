import { GoogleMap, Marker } from "@react-google-maps/api";
import { useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import useOnclickOutside from "react-cool-onclickoutside";
import Geocode from "react-geocode";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { checkLocation } from "../../api";
import { MAP_API_KEY } from "../../api/config";
import { AppContext } from "../../context/AppContext";
import "./SelectLocationMobile.css";
import { BiCurrentLocation } from "react-icons/bi";

const SelectLocationMobile = () => {
  const {
    setShowToast,
    setToastData,
    updateLocation,
    updateCurrentStore,
    setShowMap,
    currentStore,
    latLong,
    locationData,
    setLocationData,
  } = useContext(AppContext);
  const [isLocationConfirmed, setIsLocationConfirmed] = useState(false);
  const [cursor, setCursor] = useState(0);

  const containerStyle = {
    height: "100%",
    width: "100%",
  };
  const history = useHistory();

  useEffect(() => {
    console.log("let lof ", latLong);
    setLocationData({ lat: latLong?.lat, long: latLong?.long });
  }, []);

  const getLocationData = (latLng) => {
    let obj;
    Geocode.setApiKey(MAP_API_KEY);
    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
      (res) => {
        obj = {
          lat: latLng?.lat,
          long: latLng?.lng,
          name: res?.results[0]?.formatted_address,
          city: {
            name: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "administrative_area_level_1"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "administrative_area_level_1"
            )[0]?.short_name,
          },
          country: {
            name: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "country"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "country"
            )[0]?.short_name,
          },
        };
        setLocationData(obj);
        setValue(obj?.name);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      // console.log("description", description);
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng, name } = getLatLng(results[0]);
        setLocationData({
          lat: lat,
          long: lng,
          name: results[0]?.formatted_address,
        });
        setIsLocationConfirmed(true);
      });
    };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      setCursor((prevCursor) => prevCursor - 1);
    } else if (e.keyCode === 40 && cursor < data.length - 1) {
      setCursor((prevCursor) => prevCursor + 1);
    }
  };

  const handleAutoDetect = () => {
    // console.log("called")
    navigator.geolocation.getCurrentPosition((position) => {
      console.log("called", position);
      getLocationData({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  const handleConfirmLocation = () => {
    checkLocation({
      point: {
        lat: locationData?.lat,
        long: locationData?.long,
      },
    }).then((res) => {
      if (!res?.data?.isPointInPolygon) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Selected Location is Not Deliverable",
        });
      } else {
        if (
          currentStore?.store_id &&
          res?.data?.stores[0]?.store_id !== currentStore?.store_id
        ) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body confirm-popup-button-body">
                  <h2 className="fw-700">Confirm Store Change</h2>
                  <div>
                    The location you have chosen is serviced by a different
                    store, Hence your cart and wishlist will be replaced with
                    that of the selected store.
                  </div>
                  <div className="btn-group d-flex justify-content-end">
                    <button
                      className={"confirm-popup-button confirm-button-update"}
                      onClick={() => {
                        updateLocation(
                          {
                            lat: locationData?.lat,
                            long: locationData?.long,
                            location: locationData?.name,
                            city: locationData?.city,
                            country: locationData?.country,
                          },
                          res?.data
                        );
                        updateCurrentStore(res?.data?.stores[0]);
                        onClose();
                        history.push("/");
                      }}
                    >
                      {"Yes"}
                    </button>
                    <button
                      className={"cancel-popup-button cancel-button-update"}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              );
            },
            closeOnClickOutside: true,
          });
        } else {
          updateLocation(
            {
              lat: locationData?.lat,
              long: locationData?.long,
              location: locationData?.name,
              city: locationData?.city,
              country: locationData?.country,
            },
            res?.data
          );
          updateCurrentStore(res?.data?.stores[0]);
          //   setShowLocationModal(false);
          history.push("/");
        }
      }
    });
  };
  return (
    <div className="select-location-m-wrapper">
      <div className="select-location-m-header">
        <div className="select-location-m-header-container">
          <BsArrowLeft color="white" size={25} fontWeight={700} />
          <div className="select-location-m-heading">Select your Location</div>
        </div>
      </div>
      {locationData.lat && locationData.long && (
        <div
          style={{ height: "100%", position: "relative" }}
          className={"map-wrapper"}
        >
          <GoogleMap
            center={{
              lat: locationData?.lat,
              lng: locationData?.long,
            }}
            mapContainerStyle={containerStyle}
            zoom={16}
            clickableIcons={false}
            onClick={(e) => getLocationData(e.latLng.toJSON())}
          >
            <Marker
              position={{
                lat: locationData?.lat,
                lng: locationData?.long,
              }}
            />
          </GoogleMap>
          <div
            style={{
              position: "absolute",
              bottom: "240px",
              right: "20px",
              borderRadius: "50%",
              height: "35px",
              width: "35px",
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleAutoDetect}
          >
            <BiCurrentLocation size={30} color="#2D9140" />
          </div>
        </div>
      )}
      <div
        className="select-location-details-container"
        style={{
          bottom: locationData.lat && locationData.long ? 0 : "auto",
          top: locationData.lat && locationData.long ? "auto" : "8vh",
        }}
      >
        <div className="select-location-m-container">
          <div className="select-location-details-sub-heading">
            Your Location
          </div>
          <input
            style={{ position: "relative" }}
            type="text"
            className="location-modal-location-m-search-box"
            placeholder="Eg. Jumeirah Beach Residence, Dubai"
            name=""
            id=""
            onChange={(e) => handleInput(e)}
            value={value}
            onKeyDown={handleKeyDown}
            onKeyPress={(e) => {
              // console.log("press detected");
              if (e.key === "Enter") {
                // handleSelect(data[cursor]);
                // console.log("enter press detected", data[cursor]);
                setValue(data[cursor].description, false);
                clearSuggestions();

                // Get latitude and longitude via utility functions
                getGeocode({ address: data[cursor].description }).then(
                  (results) => {
                    const { lat, lng, name } = getLatLng(results[0]);
                    setLocationData({
                      lat: lat,
                      long: lng,
                      name: results[0]?.formatted_address,
                    });
                    setIsLocationConfirmed(true);
                  }
                );
              }
            }}
          />
          {data.length > 0 && (
            <div
              className="location-search-suggesstion-m-container"
              // style={{ position: "absolute", top: 0 }}
            >
              {data?.map((suggestion, i) => (
                <>
                  <div
                    className={
                      cursor === i
                        ? "location-search-suggestion active-suggestion"
                        : "location-search-suggestion"
                    }
                    onClick={handleSelect(suggestion)}
                    onMouseEnter={() => setCursor(i)}
                    onMouseLeave={() => setCursor(0)}
                  >
                    {suggestion.description}
                  </div>
                </>
              ))}
            </div>
          )}
          <button
            onClick={() => {
              if (isLocationConfirmed) {
                handleConfirmLocation();
              }
            }}
            className="location-modal-location-m-cta"
          >
            {latLong.location ? "Change Location" : "Start Shopping"}
          </button>
        </div>
      </div>
      <div className="select-location-m-container"></div>
    </div>
  );
};

export default SelectLocationMobile;
