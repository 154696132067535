import React, { useEffect, useContext, useState } from "react";
import newLogo from "../../assets/images/nesto-online-logo.svg";
import locationBg from "../../assets/images/nesto-online-cover-img1.png";
import { FiSearch } from "react-icons/fi";
import locationPicOne from "../../assets/images/nesto-online-order-now.svg";
import locationPicTwo from "../../assets/images/nesto-online-deals.svg";
import locationPicThree from "../../assets/images/nesto-online-grocery-delivery.svg";
import plane1 from "../../assets/images/paperplane-1.svg";
import plane2 from "../../assets/images/paperplane-2.svg";
import iphone1 from "../../assets/images/nesto-online-app1.png";
import iphone2 from "../../assets/images/nesto-online-app2.png";
import iphone3 from "../../assets/images/nesto-online-app3.png";
import gplay from "../../assets/images/google-logo.svg";
import appstore from "../../assets/images/apple-logo.svg";
import bannerLogo from "../../assets/images/nesto-group-logo.png";
import "./Location.css";
import Footer from "../../components/Footer/Footer";
import { useHistory } from "react-router";

import { AppContext } from "../../context/AppContext";
import { TiTickOutline } from "react-icons/ti";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Geosuggest from "react-geosuggest";
import Geocode from "react-geocode";

import { checkLocation } from "../../api";
import { MAP_API_KEY } from "../../api/config";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
function Location() {
  const history = useHistory();
  const {
    setShowToast,
    setToastData,
    updateLocation,
    updateCurrentStore,
    setShowLogin,
    profile,
    isLoggedIn,
    currentStore,
    locationData, 
    setLocationData
  } = useContext(AppContext);

  const [showMap, setShowMap] = useState(false);
  const containerStyle = {
    height: "350px",
    width: "100%"
  };
  const location = useLocation();
  useDocumentTitle("Select Location - Nesto Online Shopping");
  const getLocationData = latLng => {
    Geocode.setApiKey(MAP_API_KEY);
    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
      res => {
        setLocationData({
          lat: latLng?.lat,
          long: latLng?.lng,
          name: res?.results[0]?.formatted_address,
          city: {
            name: res?.results[0]?.address_components.filter(
              item => item?.types[0] === "administrative_area_level_1"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              item => item?.types[0] === "administrative_area_level_1"
            )[0]?.short_name
          },
          country: {
            name: res?.results[0]?.address_components.filter(
              item => item?.types[0] === "country"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              item => item?.types[0] === "country"
            )[0]?.short_name
          }
        });
      },
      error => {
        console.log(error);
      }
    );
  };

  const handleConfirmLocation = () => {
    checkLocation({
      point: {
        lat: locationData?.lat,
        long: locationData?.long
      }
    }).then(res => {
      if (!res?.data?.isPointInPolygon) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Selected Location is Not Deliverable"
        });
      } else {
        if (
          currentStore?.store_id &&
          res?.data?.stores[0]?.store_id !== currentStore?.store_id
        ) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body confirm-popup-button-body">
                  <h2 className="fw-700">Confirm Store Change</h2>
                  <div>
                    The location you have chosen is serviced by a different
                    store, Hence your cart and wishlist will be replaced with
                    that of the selected store.
                  </div>
                  <div className="btn-group d-flex justify-content-end">
                    <button
                      className={"confirm-popup-button confirm-button-update"}
                      onClick={() => {
                        updateLocation(
                          {
                            lat: locationData?.lat,
                            long: locationData?.long,
                            location: locationData?.name,
                            city: locationData?.city,
                            country: locationData?.country
                          },
                          res?.data
                        );
                        updateCurrentStore(res?.data?.stores[0]);
                        onClose();
                        history.push("/");
                      }}
                    >
                      {"Yes"}
                    </button>
                    <button
                      className={"cancel-popup-button cancel-button-update"}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              );
            },
            closeOnClickOutside: true
          });
        } else {
          updateLocation(
            {
              lat: locationData?.lat,
              long: locationData?.long,
              location: locationData?.name,
              city: locationData?.city,
              country: locationData?.country
            },
            res?.data
          );
          updateCurrentStore(res?.data?.stores[0]);
          setShowMap(false);
          history.push("/");
        }
      }
    });
  };
  const login = () => {
    setShowLogin(true);
  };
  return (
    <>
      <div className="container-fluid location-header">
        <div className="container d-flex justify-content-between align-items-center">
          <img
            className="Location-logo c-pointer"
            src={newLogo}
            alt="logo"
            onClick={() => {
              history.push("/");
            }}
          />
          {isLoggedIn && profile ? (
            <div className="d-flex">
              <div className="location-createnew">
                {`${profile?.firstname} ${
                  profile?.lastname !== "." ? profile?.lastname : ""
                }`}
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <button className="location-signin" onClick={login}>
                Sign In
              </button>
              <button className="location-createnew" onClick={login}>
                Create New Account
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="location-main">
        <div className="location-bg-box">
          <img
            className="location-bg-tr"
            src={locationBg}
            alt="Nesto Online Background Image"
          />
          <img
            className="location-bg-bl"
            src={locationBg}
            alt="Nesto Online Background Image"
          />
          {showMap ? (
            <div className="my-5 offset-md-2 col-md-8 mapview-container px-2 py-4">
              {/* {`${locationData?.lat}, ${locationData?.long}`} */}
              <GoogleMap
                center={{
                  lat: locationData?.lat,
                  lng: locationData?.long
                }}
                mapContainerStyle={containerStyle}
                zoom={16}
                clickableIcons={false}
                onClick={e => getLocationData(e.latLng.toJSON())}
              >
                <Marker
                  position={{
                    lat: locationData?.lat,
                    lng: locationData?.long
                  }}
                />
              </GoogleMap>
              <div className="px-5 d-flex justify-content-center flex-column">
                <div
                  className="m-4 position-relative d-flex justify-content-between p-2 align-items-center"
                  style={{
                    border: "1.1px solid #00983D",
                    borderRadius: "6px"
                  }}
                >
                  <span
                    className="border rounded-circle"
                    style={{
                      backgroundColor: "green",
                      color: "white"
                    }}
                  >
                    <TiTickOutline className="m-1" />
                  </span>
                  <div className="overflow-hidden text-truncate w-80 mx-2">
                    {locationData?.name}
                  </div>
                  <div
                    className="c-pointer fw-700 text-danger"
                    onClick={() => {
                      setShowMap(false);
                    }}
                  >
                    Change
                  </div>
                </div>
                <p
                  className="text-center fs-16"
                  style={{
                    color: "#7A869A"
                  }}
                >
                  Move the map pin to find your loaction and select the delivery
                  address. Please place the pin accurately.
                </p>
                <span className="d-flex justify-content-center">
                  <button
                    className="btn btn-success"
                    onClick={handleConfirmLocation}
                  >
                    Confirm Location
                  </button>
                </span>
              </div>
            </div>
          ) : (
            <div className="text-center container">
              <p className="location-main-heading">
                Enter your&nbsp;<span>Location</span>
              </p>
              <p className="location-sub-text">
                Start shopping your groceries and essentials with fast express
                delivery.
                <br />
                Select your location to get started
              </p>
              <div className="d-flex justify-content-center location-search-box-parent">
                <FiSearch className="location-search-icon" />
                <Geosuggest
                  className="col"
                  placeholder="Search Your Location"
                  inputClassName="location-search-box"
                  suggestsClassName="geo-suggest-suggests"
                  suggestItemClassName="text-justify c-pointer geo-suggest-suggests-list"
                  onSuggestSelect={e => {
                    if (e) {
                      setLocationData({
                        lat: e?.location?.lat,
                        long: e?.location?.lng,
                        name: e?.label,
                        city: {
                          name: e?.gmaps?.address_components.filter(
                            item =>
                              item?.types[0] === "administrative_area_level_1"
                          )[0]?.long_name,
                          code: e?.gmaps?.address_components.filter(
                            item =>
                              item?.types[0] === "administrative_area_level_1"
                          )[0]?.short_name
                        },
                        country: {
                          name: e?.gmaps?.address_components.filter(
                            item => item?.types[0] === "country"
                          )[0]?.long_name,
                          code: e?.gmaps?.address_components.filter(
                            item => item?.types[0] === "country"
                          )[0]?.short_name
                        }
                      });
                      setShowMap(true);
                    }
                  }}
                />
              </div>
              {!isLoggedIn && (
                <p className="location-fade-text">
                  Already A Customer?<span onClick={login}>Sign In</span>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-md-4 text-center position-relative">
              <img
                className="img-fluid"
                src={locationPicOne}
                alt="Nesto Online Order Now"
              />
              <img className="plane-1" src={plane1} alt="location pics" />
              <p className="location-illus-text">Wide range of categories</p>
              <p className="location-illus-subtext">
                Browse from wide range of categories for Grocery and households.
              </p>
            </div>
            <div className="col-md-4 text-center position-relative">
              <img
                className="img-fluid"
                src={locationPicTwo}
                alt="Nesto Online Deals"
              />
              <img className="plane-2" src={plane2} alt="location pics" />
              <p className="location-illus-text">Amazing Deals and offers</p>
              <p className="location-illus-subtext">
                Enjoy amazing deals and special offers from your favourite Nesto
                hypermarket.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={locationPicThree}
                alt="Nesto Online Grocery Delivery"
              />
              <p className="location-illus-text">Delivery on time</p>
              <p className="location-illus-subtext">
                Our highly effecient delivery team will ensure that your
                delivery is fast and on time.
              </p>
            </div>
          </div>
        </div>
        <div className="conatiner-fluid location-banner">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-md-6 h-100 text-center d-flex flex-column align-items-center justify-content-center">
                <img src={bannerLogo} alt="Nesto Group Logo" />
                <p className="location-banner-heading">
                  Get the <span>Nesto App</span>
                </p>
                <p className="location-banner-sub-text mx-xl-5">
                  Make your delivery easy by getting all kinds of grocery and
                  consumables in a couple of taps.
                </p>
                <p className="location-download-text">
                  Download The App <br />
                  <span>From</span>
                </p>
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                  <img
                    className="store-logo c-pointer"
                    src={gplay}
                    alt="gplay"
                    onClick={() =>
                      (window.location.href =
                        "https://play.google.com/store/apps/details?id=shop.nesto.ecommerce")
                    }
                  />
                  <img
                    className="store-logo c-pointer"
                    src={appstore}
                    alt="appstore"
                    onClick={() =>
                      (window.location.href =
                        "https://apps.apple.com/in/app/nesto-online-shopping/id1560646978")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="red-banner"></div>
          <img className="iphone1" src={iphone1} alt="Nesto Online App1" />
          <img className="iphone2" src={iphone2} alt="Nesto Online App2" />
          <img className="iphone3" src={iphone3} alt="Nesto Online App3" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Location;
