import moment from "moment";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import Offer from "../widgets/Offer.png";
import OfferDesktop from "../widgets/Offer-Desktop.png";
import OfferMobile from "../widgets/Offer-phone.png";

const TwoBannerImageV2 = ({ widget }) => {
  const { width } = useWindowSize();
  const { getRedirectionUrl, getScaledFontSize } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  const timeCalc = () => {
    const difference = moment(widget?.details?.endDate).diff(moment());
    const duration = moment.duration(difference);
    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();
    if (hours < 24 && Math.floor(minutes) > 60)
      return {
        time: Math.floor(hours),
        message: `${Math.floor(hours)} ${
          Math.floor(hours) > 1 ? "HOURS" : "HOUR"
        }`,
      };
    if (Math.floor(hours) <= 24 && Math.floor(minutes) < 60)
      return {
        time: Math.floor(minutes),
        message: `${Math.floor(minutes)} ${minutes > 1 ? "MINS" : "MIN"}`,
      };
    if (Math.floor(hours) >= 24 && Math.floor(minutes) >= 60)
      return {
        time: Math.floor(days),
        message: `${Math.floor(days)} ${days > 1 ? "DAYS" : "DAY"}`,
      };
    // return Math.floor(days);
  };

  return timeCalc()?.time > 0 ? (
    <div
      className="d-flex align-items-center justify-content-between grid-wrapper-1-v2"
      style={{
        background: `linear-gradient(115.14deg, ${widget?.details?.startGradient} 0%, ${widget?.details?.endGradient} 100%)`,
        // width: "12rem",
        cursor: width >= 678 ? "auto" : "pointer",
        height: "100%",
        minWidth: width > 850 ? "364px" : "200px",
        position: "relative",
      }}
    >
      <Link
        className="d-flex align-items-center justify-content-between grid-wrapper-1-v2"
        style={{ all: "unset", cursor: "pointer", height: "100%" }}
        to={() =>
          getRedirectionUrl(
            widget?.details?.redirectType,
            widget?.details?.itemCode
          )
        }
      >
        {widget?.details?.endDate && (
          <div
            style={{
              position: "absolute",
              top: width > 425 ? "10px" : "5px",
              right: "0",
              width:
                width > 425
                  ? widget < 952
                    ? "55%"
                    : width < 768
                    ? "55%"
                    : "50%"
                  : "60%",
              overflow: "hidden",
            }}
          >
            <div className="two-banner-label-text">
              {timeCalc()?.message} LEFT
            </div>
            <img
              className="two-banner-label"
              src={width > 425 ? OfferDesktop : OfferMobile}
              alt="offer-end-date"
            />
          </div>
        )}

        <div className="offer-btn-wrapper d-flex flex-column align-items-start justify-content-between">
          <div
            className="banner-image-v2-discount-wrapper"
            style={{
              fontSize: `${getScaledFontSize(
                widget?.details?.discountFontSize,
                1.2
              )}px`,
            }}
          >
            {widget?.details?.discount}{" "}
            <span className="banner-image-v2-percent">%</span>{" "}
            <span className="banner-image-v2-off">Off</span>{" "}
          </div>
          <div
            className="banner-image-v2-title"
            style={{
              fontSize: `${getScaledFontSize(
                widget?.details?.titleFontSize,
                1.2
              )}px`,
            }}
          >
            {widget?.details?.title}
          </div>
          <div>
            <div
              className="banner-image-v2-original-price"
              style={{
                fontSize: `${getScaledFontSize(
                  widget?.details?.originalPriceFontSize,
                  1.2
                )}px`,
              }}
            >
              {widget?.details?.originalPrice} AED
            </div>
            <div
              className="banner-image-v2-discounted-price"
              style={{
                fontSize: `${getScaledFontSize(
                  widget?.details?.discountPriceFontSize,
                  1.2
                )}px`,
              }}
            >
              {widget?.details?.discountPrice} AED
            </div>
          </div>
          {width >= 768 && (
            <Link
              to={() =>
                getRedirectionUrl(
                  widget?.details?.redirectType,
                  widget?.details?.itemCode
                )
              }
              style={{ all: "unset", cursor: "pointer" }}
            >
              <button
                className="banner-button banner-btn-v2"
                style={{
                  background: widget?.details?.buttonColor,
                  color: widget?.details?.buttonFontColor,
                }}
                // onClick={() =>
                //   handleRedirect(
                //     widget?.details?.redirectType,
                //     widget?.details?.itemCode
                //   )
                // }
              >
                Shop Now
              </button>
            </Link>
          )}
        </div>
        <img
          className="banner-v2-images"
          src={
            width >= 445
              ? widget?.details?.imageUrlWeb
                ? widget?.details?.imageUrlWeb
                : widget?.details?.imageUrl
              : widget?.details?.imageUrl
          }
          alt="fruits-and-vegetables"
        />
      </Link>
    </div>
  ) : (
    <div></div>
  );
};

export default TwoBannerImageV2;
