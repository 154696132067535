import { createContext, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const { width } = useWindowSize();
  const [searchProductName, setSearchProductName] = useState("");
  const [refrenceId, setRefrenceId] = useState("");
  const [defaultLocale, setDefaultLocale] = useState({});
  const [appDesignData, setAppDesignData] = useState({});
  const [isDemo, setIsDemo] = useState(false);
  const [addressBook, setAddressBook] = useState([]);
  const [mobileBreakPoint, setMobileBreakPoint] = useState("768px");
  const [showLogin, setShowLogin] = useState(false);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [wishListUpdated, setWishListUpdated] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [couponApplied, setCouponApplied] = useState(false);
  const [locationData, setLocationData] = useState({
    lat: 0,
    long: 0,
    name: "",
  });

  const [inaamData, setInaamData] = useState({});
  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem("cookies_accepted")
      ? localStorage.getItem("cookies_accepted")
      : false
  );
  const [mapbar, setMapbar] = useState(false);
  const [showLoder, setShowLoder] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [categories, setCategories] = useState(
    localStorage.getItem("all_cat")
      ? JSON.parse(localStorage.getItem("all_cat"))
      : []
  );
  const [store, setStore] = useState(
    localStorage.getItem("store_data")
      ? JSON.parse(localStorage.getItem("store_data"))
      : {}
  );
  const [latLong, setLatLong] = useState(
    localStorage.getItem("location_data")
      ? JSON.parse(localStorage.getItem("location_data"))
      : {
          lat: 0,
          long: 0,
          location: "",
        }
  );

  const [addressLocation, setAddressLocation] = useState(
    localStorage.getItem("address_location")
      ? JSON.parse(localStorage.getItem("address_location"))
      : {
          lat: 0,
          long: 0,
          location: "",
          store_id: "",
          city: "",
          country: "",
        }
  );
  const [currentStore, setCurrentStore] = useState(
    localStorage.getItem("current_store")
      ? JSON.parse(localStorage.getItem("current_store"))
      : {}
  );
  const [searchProducts, setSearchProducts] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState();
  const [activePaymentPayload, setActivePaymentPayload] = useState(
    localStorage.getItem("active_payload")
      ? JSON.parse(localStorage.getItem("active_payload"))
      : {}
  );

  const [checkoutTotals, setCheckoutTotals] = useState(
    localStorage.getItem("checkout_totals")
      ? JSON.parse(localStorage.getItem("checkout_totals"))
      : {}
  );
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});
  const [cartData, setCartData] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("access_token") ? true : false
  );
  const [profile, setProfile] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : ""
  );
  const [cartId, setCartId] = useState(0);
  const [categoryData, setCategoryData] = useState([]);

  const [homePageOrder, setHomePageOrder] = useState([]);
  const setLoggedIn = ({ access_token }) => {
    setIsLoggedIn(true);
    localStorage.setItem("access_token", access_token);
  };

  const updateUser = (data) => {
    setProfile(data);
    localStorage.setItem("user_data", JSON.stringify(data));
  };

  const logout = () => {
    setIsLoggedIn(false);
    setProfile({});
    setCartId();
    setInaamData([]);
    setCartData([]);
    setWishlistData([]);
    setCouponApplied(false);
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("checkout_totals");
    localStorage.removeItem("active_payload");
    localStorage.removeItem("address_location");
  };

  const updateLocation = (cord, data) => {
    setLatLong(cord);
    setStore(data);
    localStorage.setItem("location_data", JSON.stringify(cord));
    localStorage.setItem("store_data", JSON.stringify(data));
  };
  const updateCategory = (payload) => {
    setCategories(payload);
    localStorage.setItem("all_cat", JSON.stringify(payload));
  };

  const updateActivePaymentPayload = (data, totals) => {
    setActivePaymentPayload(data);
    localStorage.setItem("active_payload", JSON.stringify(data));
    setCheckoutTotals(totals);
    localStorage.setItem("checkout_totals", JSON.stringify(totals));
  };

  const updateCurrentStore = (storeData) => {
    setCurrentStore(storeData);
    localStorage.setItem("current_store", JSON.stringify(storeData));
  };
  const updateAddressLocation = (selectedLocation) => {
    setAddressLocation(selectedLocation);
    localStorage.setItem("address_location", JSON.stringify(selectedLocation));
  };

  const updateCookiesAccepted = () => {
    setCookiesAccepted(true);
    localStorage.setItem("cookies_accepted", true);
  };

  const addToFavourites = (prodData) => {
    setWishlistData((oldWishList) => [...oldWishList, prodData]);
  };

  const removeFromFavourites = (id) => {
    setWishlistData((oldWishList) =>
      oldWishList.filter((item) => item.entity_id !== id)
    );
  };

  const initializeFavourites = (data) => {
    setWishlistData(data);
  };

  const addToCartContext = (item_id, prodData, qty) => {
    setCartData((oldCart) => [
      ...oldCart,
      {
        tax_included_price:
          prodData?.tax_included_special_price > 0
            ? prodData?.tax_included_special_price
            : prodData?.tax_included_price,
        salable_qty: prodData?.salable_qty || [],
        image: prodData?.image,
        tax_percentage: prodData?.tax_percentage,
        price:
          prodData?.tax_included_special_price > 0
            ? prodData?.special_price
            : prodData?.price,
        item_id: item_id,
        name: prodData?.name,
        product_type: "simple",
        qty: qty,
        quote_id: cartId,
        sku: prodData?.sku,
        is_salable: prodData?.is_salable,
      },
    ]);
  };
  const updateCartContext = (item_id, qty) => {
    setCartData((oldCart) => [
      ...oldCart.map((item) => {
        if (item.item_id === item_id) {
          item.qty = qty;
        }
        return item;
      }),
    ]);
  };

  const removeFromCartContext = (item_id) => {
    setCartData((oldCart) =>
      oldCart.filter((item) => item.item_id !== item_id)
    );
  };

  const initializeCart = (data) => {
    setCartIdentity(data?.id);
    setCartDataFN(data?.items);
  };
  const setCartIdentity = (id) => {
    setCartId(id);
  };

  const setCartDataFN = (items) => {
    setCartData(items);
  };

  const updateAddressList = (data) => {
    console.log(data);
  };

  // Find component design data
  const getComponentDesignData = async (pageId, sectionCode, position) => {
    let componentData;

    componentData = await appDesignData?.data?.data?.pages
      .filter((page) => page._id === pageId)[0]
      ?.content.filter(
        (section) => section.subTemplate.sectionCode === sectionCode
      )
      .filter((item) => item.position === position);
    return componentData;
  };

  const getRedirectionUrl = (redirectType, itemCode) => {
    switch (redirectType) {
      case "TEMPLATE_PAGE": {
        return `/page/${itemCode}`;
      }
      case "CATEGORY": {
        return `/categories/${itemCode}`;
      }
      case "PRODUCT": {
        return `/products/${store?.sap_website_id}-${itemCode}`;
      }
      case "EXTERNAL_LINK": {
        return itemCode;
      }
      default: {
        return "";
      }
    }
  };

  // Scaled font size

  const getScaledFontSize = (fontSize, scalingMultiplier) => {
    if (width >= 768) {
      return parseInt(fontSize) * scalingMultiplier;
    } else if (width >= 425) {
      return (
        (parseInt(fontSize) * scalingMultiplier + parseInt(fontSize)) * 0.5
      );
    } else {
      return parseInt(fontSize);
    }
  };

  const value = {
    isLoggedIn,
    profile,
    showLogin,
    cartData,
    cartId,
    homePageOrder,
    searchProducts,
    isPageLoading,
    wishlistData,
    showLoder,
    activePaymentPayload,
    toastData,
    showToast,
    store,
    latLong,
    mapbar,
    categories,
    currentStore,
    checkoutTotals,
    addressLocation,
    categoryData,
    breadcrumbs,
    cookiesAccepted,
    searchProductName,
    addressBook,
    cartUpdated,
    wishListUpdated,
    inaamData,
    timeSlots,
    setTimeSlots,
    setInaamData,
    setWishListUpdated,
    setCartUpdated,
    setAddressBook,
    setSearchProductName,
    updateCookiesAccepted,
    setBreadcrumbs,
    setCategoryData,
    updateAddressLocation,
    updateCurrentStore,
    updateCategory,
    setMapbar,
    updateLocation,
    setShowToast,
    setToastData,
    updateActivePaymentPayload,
    setShowLoder,
    addToFavourites,
    removeFromFavourites,
    initializeFavourites,
    addToCartContext,
    updateCartContext,
    removeFromCartContext,
    initializeCart,
    setIsPageLoading,
    setSearchProducts,
    setHomePageOrder,
    setShowLogin,
    logout,
    setLoggedIn,
    updateUser,
    couponApplied,
    setCouponApplied,
    appDesignData,
    getComponentDesignData,
    getRedirectionUrl,
    setAppDesignData,
    isDemo,
    setIsDemo,
    getScaledFontSize,
    defaultLocale,
    setDefaultLocale,
    couponApplied,
    setCouponApplied,
    refrenceId,
    setRefrenceId,
    locationData,
    setLocationData,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
