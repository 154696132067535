import { put, post, get, del } from "./NetworkUtils";
import {
  MAGENTO_BASE_URL,
  LAMBDA_BASE_URL,
  PAYMENT_BASE_URL,
  HOME_BUILDER_BASE_URL,
  ZMS_BASE_URL,
} from "./config";
import axios from "axios";

//Dynamic HomePage APIs
// APIs without Authorization

// export const getDynamicHomePage = store_id => {
//   const URL = `fetch?source=web&store_id=${store_id}`;
//   return get(DYNAMIC_HOMEPAGE_BASE_URL, URL, false, false);
// };
/*============================================================*/

//Magento APIs
// APIs without authorization

export const getCategoryName = (catID) => {
  const URL = `V1/categories?rootCategoryId=${catID}&depth=0`;
  return get(MAGENTO_BASE_URL, URL, false);
};
export const getProduct = (sku, store_id) => {
  const URL = `V1/customapi/products/${sku}?store_id=${store_id}`;
  return get(MAGENTO_BASE_URL, URL, false);
};
export const getCategoryItems = (id, limit, page, store_id) => {
  const URL = `V1/customapi/pagination/categories/${id}?limit=${limit}&currrentpage=${page}&store_id=${store_id}`;
  return get(MAGENTO_BASE_URL, URL, false);
};

export const getCategoryItemsNoPage = (id, store_id) => {
  const URL = `V1/customapi/categories/${id}?store_id=${store_id}`;
  return get(MAGENTO_BASE_URL, URL, false);
};

export const getMainCategories = (store_id) => {
  const URL = `V1/customapi/categoriestree?store_id=${store_id}&cron=${1}`;
      // V1/customapi/categoriestree?category_id=${2585}&store_id=${store_id}&cron=${1}
  // const URL = `V1/customapi/categoriestree/${
  //   process?.env?.REACT_APP_ENV === "production" ? 2585 : 1757
  // }?store_id=${store_id}`;
  return get(MAGENTO_BASE_URL, URL, false);
};

export const getCategoryImage = (id) => {
  const URL = `V1/categories/` + id;
  return get(MAGENTO_BASE_URL, URL, false);
};
export const getBestSellers = (store_id) => {
  const URL = `V1/customapi/bestsellers?store_id=${store_id}`;
  return get(MAGENTO_BASE_URL, URL, false);
};
export const getOTP = (payload) => {
  const URL = `/V1/request_otp`;
  return post(MAGENTO_BASE_URL, URL, payload, false);
};
export const verifyOTP = (payload) => {
  const URL = `V1/registerverify/`;
  return post(MAGENTO_BASE_URL, URL, payload, false);
};
export const createNewUser = (payload) => {
  const URL = `V1/registertw/`;
  return post(MAGENTO_BASE_URL, URL, payload, false);
};
export const getProductFromSku = (store_code, payload) => {
  const URL = `${store_code}/V1/customapi/getproductfromsku`;
  return post(MAGENTO_BASE_URL, URL, payload, false);
};

// APIs with Authorization

export const getUser = () => {
  const URL = `V1/customers/me/`;
  return get(MAGENTO_BASE_URL, URL);
};
export const updateUserData = (payload) => {
  const URL = `V1/customers/me/`;
  return put(MAGENTO_BASE_URL, URL, payload);
};

export const getSavedCards = () => {
  const URL = `V1/ngenius_customer_savedcards/customers/me`;
  return get(MAGENTO_BASE_URL, URL);
};
// export const deleteSavedCard = (cardId) => {
//   const URL = `V1/ngenius_customer_savedcards/customers/deletesavedcard/me/${cardId}`;
//   return del(MAGENTO_BASE_URL, URL);
// };

export const deleteSavedCard = (payload) => {
  const URL = `deletesavecard`;
  return post(PAYMENT_BASE_URL, URL, payload);
};

export const getCart = (store_code) => {
  const URL = `${store_code}/V1/carts/mine/`;
  return get(MAGENTO_BASE_URL, URL);
};
export const createCart = (store_code) => {
  const URL = `${store_code}/V1/carts/mine/`;
  return post(MAGENTO_BASE_URL, URL);
};
export const addToCart = (store_code, payload) => {
  const URL = `${store_code}/V1/carts/mine/items`;
  return post(MAGENTO_BASE_URL, URL, payload);
};
export const deleteFromCart = (store_code, id) => {
  const URL = `${store_code}/V1/carts/mine/items/${id}`;
  return del(MAGENTO_BASE_URL, URL);
};
export const updateToCart = (store_code, id, payload) => {
  const URL = `${store_code}/V1/carts/mine/items/${id}`;
  return put(MAGENTO_BASE_URL, URL, payload);
};
export const getTimeSlots = (store_code, currentTime, store_id) => {
  const URL = `${store_code}/V1/delivery_date/${currentTime}?store_id=${store_id}`;
  return get(MAGENTO_BASE_URL, URL, false);
};
export const getInaamPoints = (phoneNumber) => {
  const URL = `V1/inaam/user_point/${phoneNumber}`;
  return get(MAGENTO_BASE_URL, URL);
};

// export const postTimeSlots = (Payload) => {
//   const URL = `V1/delivery_date/save_data`;
//   return post(MAGENTO_BASE_URL, URL, Payload);
// };

export const searchProduct = (name, store_id, page, limit) => {
  const URL = `V1/customapi/searchproducts/${name}?store_id=${store_id}&currrentpage=${page}&limit=${limit}`;
  return get(MAGENTO_BASE_URL, URL, false);
};

export const addShippingOrderDetails = (payload) => {
  const URL = `/V1/customapi/carts/mine/shipping-information/updateQuotedata`;
  return put(MAGENTO_BASE_URL, URL, payload);
};

export const addShipping = (store_code, payload) => {
  const URL = `${store_code}/V1/carts/mine/shipping-information`;
  return post(MAGENTO_BASE_URL, URL, payload);
};

// export const placeOrder = (payload) => {
//   const URL = `V1/carts/mine/payment-information`;
//   return post(MAGENTO_BASE_URL, URL, payload);
// };

export const createOrder = (store_code, payload) => {
  const URL = `${store_code}/V1/customapi/createorder/`;
  return post(MAGENTO_BASE_URL, URL, payload);
};
export const getOrders = () => {
  const URL = `V1/orderlist?allstore=true`;
  return post(MAGENTO_BASE_URL, URL);
};
export const getOrder = (id) => {
  const URL = `V1/customapi/orders/${id}`;
  return get(MAGENTO_BASE_URL, URL);
};
export const getWishList = (store_code) => {
  const URL = `${store_code}/V1/wishlist/items`;
  return get(MAGENTO_BASE_URL, URL);
};
export const addToWishList = (product_id, store_code) => {
  const URL = `${store_code}/V1/wishlist/add/${product_id}`;
  return post(MAGENTO_BASE_URL, URL);
};
export const removeFromWishList = (product_id, store_code) => {
  const URL = `${store_code}/V1/wishlist/delete/${product_id}`;
  return post(MAGENTO_BASE_URL, URL);
};
// export const getSavedCards = (customer_id , env) => {
//   const URL = `customer_cards?customer_id=${customer_id}&env=${env}`;
//   return get(PAYMENT_BASE_URL, URL);
// };
/*============================================================*/

//Lambda APIs
// APIs without Authorization
export const checkLocation = (payload) => {
  const URL = `basic/point-in-polygon?version=2`;
  return post(LAMBDA_BASE_URL, URL, payload, false);
};
export const getNotification = (id) => {
  const URL = `/basic/notification/customer/${id}`;
  return get(LAMBDA_BASE_URL, URL, false);
};
export const markNotificationsRead = (payload) => {
  const URL = `/basic/notification/seen`;
  return post(LAMBDA_BASE_URL, URL, payload, false);
};
export const clearAllNotifications = (payload) => {
  const URL = `/basic/notification/clear`;
  return post(LAMBDA_BASE_URL, URL, payload, false);
};
// APIs with Authorization
export const cancelOrder = (payload) => {
  const URL = `order/cancel`;
  return post(LAMBDA_BASE_URL, URL, payload);
};
export const returnOrder = (payload) => {
  const URL = `order/return`;
  return post(LAMBDA_BASE_URL, URL, payload);
};
export const editOrder = (payload) => {
  const URL = `order/update`;
  return post(LAMBDA_BASE_URL, URL, payload);
};

/** Coupon code apis */
export const removeCoupon = store_code => {
  const URL = `${store_code}/V1/carts/mine/coupons`;
  return del(MAGENTO_BASE_URL, URL, "access_token");
};

export const getCouponInfo = (coupon_code, store_code) => {
  const URL = `${store_code}/V1/couponinfo/${coupon_code}`;
  return get(MAGENTO_BASE_URL, URL, "no_token");
};

export const addCoupon = (coupon_code, store_code) => {
  const URL = `${store_code}/V1/carts/mine/coupons/${coupon_code}`;
  return put(MAGENTO_BASE_URL, URL, {}, "access_token");
};


/*============================================================*/

// Payment APIs
// APIs without Authorization
export const checkPaymentStatus = (refId) => {
  const URL = `payment_status?order_ref=${refId}`;
  return get(PAYMENT_BASE_URL, URL, false);
};
// APIs with Authorization
export const cardPayment = (payload) => {
  const URL = `createorder`;
  return post(PAYMENT_BASE_URL, URL, payload);
};

const appDesignDataConfig = {
  headers: { Authorization: `Bearer hbs` },
};

export const getAppDesignData = async (sap_website_id) => {
  try {
    const response = await axios.get(
      `${HOME_BUILDER_BASE_URL}template/store/${sap_website_id}`,
      appDesignDataConfig
    );
    // ${sap_website_id}
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const getDemoAppDesignData = async (token, templateId) => {
  try {
    const response = await axios.get(
      `${HOME_BUILDER_BASE_URL}template-demo/${templateId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    console.log(e);
  }
};
