import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";

const TwoBannerImageV1 = ({ widget }) => {
  const { getRedirectionUrl, getScaledFontSize } = useContext(AppContext);
  const history = useHistory();
  const { width } = useWindowSize();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };
  return (
    <div
      className="d-flex align-items-center justify-content-between grid-wrapper-1"
      style={{
        background: `linear-gradient(${widget?.details?.startGradient} 100%, ${widget?.details?.endGradient} 100%)`,
      }}
    >
      <div className="offer-btn-wrapper d-flex flex-column align-items-start justify-content-around">
        <div
          className="offer-title"
          style={{
            fontSize: `${getScaledFontSize(
              widget?.details?.titleFontSize,
              2.2
            )}px`,
          }}
        >
          {widget?.details?.title}
        </div>
        <Link
          to={() =>
            getRedirectionUrl(
              widget?.details?.redirectType,
              widget?.details?.itemCode
            )
          }
          style={{ all: "unset", cursor: "pointer" }}
        >
          <button
            className="banner-button"
            style={{
              background: widget?.details?.buttonColor,
              color: widget?.details?.buttonFontColor,
            }}
          >
            {widget?.details?.buttonName}
          </button>
        </Link>
      </div>
      <img
        className="banner-images"
        src={
          width >= 445
            ? widget?.details?.imageUrlWeb
              ? widget?.details?.imageUrlWeb
              : widget?.details?.imageUrl
            : widget?.details?.imageUrl
        }
        alt="fruits-and-vegetables"
      />
    </div>
  );
};

export default TwoBannerImageV1;
